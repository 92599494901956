/* For Sell Page */
.login_panel {
  padding: 0;
  float: right;
  margin-top: 0;
}
.login_panel li {
  list-style: none;
  float: left;
  position: relative;
  padding: 0 13px;
}
ul.login_panel li > ul.dropdown-menu {
  padding: 0;
  margin: 0;
  background: #fff;
  border: 1px solid #ccc;
  position: absolute;
  right: 15px;
}
ul.login_panel li > ul.dropdown-menu li {
  float: none;
  border-bottom: 1px solid #cccccc;
  color: #777;
  padding: 0;
}
ul.login_panel li > ul.dropdown-menu li:last-child {
  border-bottom: 0;
}
ul.login_panel li > ul.dropdown-menu li:after {
  content: "";
}
ul.login_panel li > ul.dropdown-menu li a {
  display: block;
  padding: 0 13px;
}
ul.login_panel li > ul.dropdown-menu li a:hover {
  background: #e0e0e0;
  color: #333333;
}
.login_panel li a {
  text-decoration: none;
  color: #777777;
}
.login_panel li:after {
  content: "|";
  display: block;
  position: absolute;
  float: right;
  top: 0;
  right: 0;
}
.login_panel li:last-child:after {
  content: "";
}
.login_panel li:last-child {
  padding-right: 0;
}

.phone {
  display: block;
  height: 24px;
  background: url(/images/phone_black_outline.svg) no-repeat;
  padding-left: 1.4rem;
  text-decoration: none;
  float: right;
  margin-top: 9px;
  color: #bc285c;
  line-height: 17px;
  background-size: 20px;
}
.phone span {
  color: #a0a0a0;
}
.login_panel li:hover .open {
  display: block;
}
.open {
  display: none;
  float: left;
  height: auto;
  position: absolute;
  text-align: left;
  top: 28px;
  z-index: 1050;
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  width: 233%;
  right: 0;
}
.open a {
  color: #777;
}
.open a {
  border-bottom: 1px solid #cccccc;
  color: #777;
  float: left;
  font-size: 12px;
  list-style: none outside none;
  margin: -1px 0;
  overflow: hidden;
  padding: 4px 0;
  text-align: center;
  width: 100%;
}
.open a:hover {
  background: none repeat scroll 0 0 #e0e0e0;
  color: #333333;
}

#menu {
  width: 100%;
  margin: 0 auto !important;
  padding: 0;
  position: relative;
  list-style: none outside none;
}

.navbarNav {
  margin: 0 auto !important;
  display: flex;
  align-items: center;
}
.navbarNav ul{
  list-style: none;
}
.navbarNav ul li{
  position: relative;
}

.nav_item {
  display: inline-flex;
  font-size: 14px;
  margin: 0;
  padding: 5px !important;
  color: #030712;
  align-items: center;
  gap: 5px;
}
.navbarNav ul li:hover .nav_item{
  color: #0474B4 !important;
}
.nav_item:last-child {
  border: none;
}

.navbarBrand {
  margin-left: 10px;
}
/* Navbar end */

/* Searchbar section start */

.banner_img {
  z-index: -1;
}
.slogan {
  width: 83.33%;
  margin: auto;
}
.banner span {
  color: #ff99cc;
}
.search {
  width: 48%;
  height: 40px;
  margin: auto;
  margin-top: 30px;
  border-radius: 3px;
  /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  -webkit-border-radius: 3px;
  /* useful if you don't want a bg color from leaking outside the border: */
  background-clip: padding-box;
  /* Android 2.2+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 4+ */
  background: #ffffff;
  position: relative;
}
.inner_banner {
  margin-top: 0 !important;
}
.search .searchbtn {
  color: #ffffff;
  display: block;
  height: 32px;
  margin: 0;
  outline: medium none;
  padding: 0;
  width: 50px;
  background: url(/images/search.svg) 12px 3px no-repeat;
  border-radius: 3px;
  /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  -webkit-border-radius: 3px;
  border: none;
  float: right;
}
.or {
  display: block;
  text-align: center;
  margin: 20px 0;
}

.request_button {
  border-color: #bc285c;
  border-radius: 3px;
  /* Android 2.1+, Chrome, Firefox 4+, IE 9+, iOS 4+, Opera 10.50+, Safari 5+ */
  -webkit-border-radius: 3px;
  background: #bc285c;
  font-size: 18px;
  margin: auto;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  position: relative;
}
.request_button:hover {
  background: #017ab3;
}
.sticky {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  border-top: 0;
  background: #017ab3;
  padding: 20px 0;
}
.msg h3 {
  width: 100%;
  color: #ffffff;
  font-size: 1.5em;
  text-align: center;
  font-weight: 700 !important;
}
/* Seachabr section end */
/* Override autocomplete properties  */
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 4px 9px !important;
  border: none !important;
}
.Mui-focused {
  border: none !important;
}
.marque-msg {
  background: #cde5f4;
  color: #000000;
  margin: 20px 0;
  padding: 10px;
  font-size: 16px;
  text-align: center;
}
.charity_message_box {
  width: 100%;
  border-bottom: 4px solid #44758d;
  padding: 20px 0;
}
.charity_message_box h2 {
  font-size: 26px;
  color: #777777;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  width: 90%;
}
.charity_message_box h2 a {
  color: #777777;
  text-decoration: none;
}
.h2 {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  color: #bc285c;
  text-align: center;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 20px;
}
.step {
  width: 100%;
  background: url(/images/step_bg.jpg) repeat;
  padding-bottom: 20px;
  overflow: hidden;
  margin-top: 10px;
}
.step > section {
  margin: 0 auto;
}
.step h2,
.step p {
  color: #fff;
  text-align: center;
  width: 90%;
  margin: 16px auto;
}
.step_top {
  width: 100%;
  height: 20px;
  background: url(/images/step_top_bg.jpg) repeat-x;
}
.all_step {
  text-align: center;
  margin-top: 20px;
}
.all_step p a {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.all_step_link {
  color: #fff;
  display: block;
  font-size: 14px;
  font-style: italic;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}
.h3 {
  text-align: left;
  font-family: "Lato", sans-serif;
  font-size: 26px;
  color: #bc285c;
  margin-top: 0;
  margin-bottom: 20px;
}

.product {
  margin: 0.3rem;
  display: inline-block;
  transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -o-transition: all 0.2s ease 0s;
  padding-bottom: 10px;
}
.product_img {
  margin-bottom: 20px;
}
.fnt-wght-bld {
  font-weight: bold !important;
}
.image_caption_text {
  font-size: 14px;
  margin: 5px;
  display: flex;
  align-items: center !important;
}
.img_thumb {
  width: auto !important;
  padding: 5px; /* Some padding */
  max-height: 150px; /* Set a small width */
  max-width: 150px;
}
.category_top {
  background: #f6f7f9;
  padding-right: 20px;
}
.category_top h1 {
  text-align: left;
  vertical-align: bottom;
  padding-top: 15px;
}
