.wrapper {
  width: 100%;
}
.align_right {
  text-align: right;
}
.align_left {
  text-align: left;
}
.clr {
  clear: both;
}

.pink_col {
  padding-right: 2.5px;
}
.pink {
  background: #ba0049;
  padding: 30px 0;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.pink a {
  text-decoration: none;
}
.blue_col {
  padding-left: 2.5px;
}
.blue {
  background: #0070af;
  padding: 30px 0;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.blue a {
  text-decoration: none;
}
.white_text {
  width: 100%;
  text-align: center;
  color: #ffffff;
  margin: 0 auto;
  padding-bottom: 30px;
}
.big_text {
  font-size: 60px;
}
.small_text {
  font-size: 24px;
}
.black_btn {
  width: 18%;
}
.sell_btn {
  padding: 10px;
  color: #ffffff;
  background-color: #0070af;
  font-size: 18px;
  margin: auto;
  line-height: 40px;
}
.buy_btn {
  padding: 10px;
  color: #ffffff;
  background-color: #ba0049;
  font-size: 18px;
  margin: auto;
  line-height: 40px;
  border: none;
}
.devices {
  width: 82%;
}
.testimonial_heading {
  text-align: center;
  color: #000000;
  font-size: 16px;
  padding-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}
.featured_bg {
  background: #ffffff;
  padding: 30px 0 20px 0;
  color: #000000;
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
}
.logos {
  padding: 0;
  margin: 20px auto;
  width: 99%;
}
.Sell {
  background: #000000;
  text-decoration: none;
  color: #ffffff;
}
.Sell a {
  background: #000000;
  text-decoration: none;
  color: #ffffff;
}
.Sell:hover {
  background: #71002c;
  text-decoration: none;
  color: #ffffff;
}
.buy {
  background: #000000;
  text-decoration: none;
  color: #ffffff;
}
.buy a {
  background: #000000;
  text-decoration: none;
  color: #ffffff;
}
.buy:hover {
  background: #00476f;
  text-decoration: none;
  color: #ffffff;
}
.main {
  width: 80%;
  margin: 0 auto;
}
.device_box {
  float: left;
  padding: 19px 0 0 0;
  margin-right: 19px;
}
.device_box:last-child {
  margin-right: 0;
}
.device_img {
  padding: 0 5px;
}
.device_text {
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  padding-top: 8px;
}
.device_text a {
  color: #ffffff !important;
}
.buy_devices {
  float: left;
  width: 100%;
}
.buy_device_box {
  float: left;
  padding: 19px 0 0 0;
  margin-left: 19px;
}
.buy_device_box:first-child {
  margin-left: 0;
}
.pink_black_btn_pad_left {
  padding-left: 0 !important;
}
.bordered_box {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 10px 0;
}
.device_box_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 5px;
}
.device_box_card {
  box-shadow: none;
  margin: 0 !important;
}
.device_box_image {
  width: auto;
  height: 80px;
}
.device_box_image_caption {
  font-size: 12px;
  line-height: 16px;
}
.device_box_image_card_body {
  padding: 16px !important;
}
/*=========================== For responsive===========================*/
@media all and (min-width: 1367px) {
  .lnd_pg_lt {
    max-width: 1366px;
    margin: 0 auto;
  }
}

@media all and (max-width: 1243px) {
  .buy_device_box {
    float: left;
    padding: 19px 0 0 0;
    margin-left: 19px;
  }
  .top_left {
    width: 20%;
  }
}
@media all and (max-width: 1176px) {
  .buy_devices {
    width: 75%;
  }
}
@media all and (max-width: 1024px) {
  .devices {
    width: 77.5%;
  }
}
@media all and (max-width: 980px) {
  .devices {
    width: 71.5%;
  }
  .buy_devices {
    width: 70%;
    float: right;
  }
  .device_box {
    margin-right: 2%;
  }
  .buy_device_box {
    margin-left: 2%;
  }
  .bordered_box {
    border: none;
  }
}
@media all and (max-width: 823px) {
  .about_us_to_text p,
  .about_us_body img {
    width: 100%;
  }
  .about_us_body span img {
    width: auto;
  }
}
@media all and (max-width: 768px) {
  .devices {
    width: 70%;
  }
  .buy_devices {
    width: 70%;
    float: right;
  }
  .device_box {
    margin-right: 2%;
  }
  .buy_device_box {
    margin-left: 0;
  }
  .login-panel li > a {
    text-decoration: none;
    color: #777777;
    width: 24px;
    height: 24px;
    display: block;
  }
  .login-panel li:after {
    content: none;
  }
  .login-panel li:first-child {
    padding: 0 !important;
  }
  .static-page {
    margin: 0 !important;
  }
  .whyBudli,
  .whyBudli2 {
    float: none;
    width: 100%;
  }
  .whyBudli:nth-child(2) {
    text-align: center;
    width: 100%;
  }
  .card-text .main {
    width: 100%;
  }
  .step_section .all_step_container {
    float: none;
    width: 100%;
  }
  .video-responsive iframe {
    width: 100% !important;
  }
  .about_us_body iframe {
    width: 100% !important;
  }
  a.request-button {
    width: 45%;
  }
  .card {
    margin: 0 !important;
  }
  .card-slider {
    margin-right: 10px !important;
  }
}
@media all and (max-width: 736px) {
  .devices {
    width: 68.5%;
  }
  .buy_devices {
    width: 70%;
    float: right;
  }
  .device_box {
    margin-right: 1%;
  }
  .buy_device_box {
    margin-left: 2%;
  }
}
@media all and (max-width: 700px) {
  .devices {
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .device_box {
    float: none;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
  .pink_col {
    padding-right: 15px;
  }
  .pink {
    padding-bottom: 0 !important;
    margin-bottom: 5px;
  }
  .blue_col {
    padding-left: 15px;
  }
  .black_btn {
    float: none;
    width: 60%;
  }
  .buy_right {
    text-align: center;
  }
  .buy_devices {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .buy_device_box {
    float: none;
    display: inline-block;
    margin-right: 5%;
  }
  .footer_right {
    width: 40%;
  }
  .align_right {
    text-align: center;
  }
  .pink_black_btn_pad_left {
    padding-left: 15px !important;
  }
  .sell_btn {
    margin-bottom: 20px;
  }
}
@media all and (max-width: 640px) {
  .top_left {
    width: 50%;
    margin: 0;
    margin-bottom: 10px;
  }
  .top_right {
    width: 50%;
    margin: 0;
  }
  .search {
    width: auto;
  }
  .img-thumb {
    max-width: 100px;
  }
  .device_box_image {
    height: 60px;
  }
  .device_box_image_card_body {
    padding: 5px !important;
  }
}
@media all and (max-width: 480px) {
  .black_btn {
    width: 65%;
  }
  .footer_right {
    width: 68%;
  }
  .device_box_image {
    height: 40px;
  }
}

@media all and (max-width: 320px) {
  .top_left {
    width: 42%;
  }
  .black_btn {
    width: 68%;
  }
  .buy_devices {
    float: left;
  }
  .buy_device_box {
    margin-left: 0;
  }
}
